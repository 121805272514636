import React from "react";
import styled from "styled-components";
import SingleProjectPage from "../../components/SingleProjectPage";
import theme from "../../../config/theme";

const Page = styled.div`
  .image-wrapper img {
    max-width: 500px;
    margin: 0 auto;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .image-wrapper img {
      max-width: 100%;
    }

    .hero-section {
      .row {
        align-items: center;
      }

      .detail {
        padding-bottom: 60px;
      }
    }
  }
`;

const ProjectPage = props => (
  <Page>
    <SingleProjectPage {...props} />
  </Page>
);

export default ProjectPage;
