import React, { useMemo } from "react";
import styled from "styled-components";
import { Box } from "grommet";
import Slider from "react-slick";
import { Container } from "../elements/Layout";
import { Title } from "../elements/Text";
import ProjectCard from "./ProjectCard";
import { LinkButton } from "../elements/Button";
import { PROJECTS_PAGE } from "../../config/routes";
import { projects } from "../data/posts";
import theme from "../../config/theme";

const Section = styled.section`
  margin-bottom: 80px;
  overflow: hidden;

  .title {
    margin-bottom: 40px;
  }

  .card {
    max-width: 369px;
    padding: 30px;
    margin: 0 auto;
    overflow: hidden;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .slider-container {
      .slick-dots {
        bottom: -25px;
      }
    }
  }

  @media (min-width: ${theme.breakpoints.xlarge}) {
    .slider-container {
      max-width: 1100px;
    }
  }
`;

const LatestWorksSection = ({ currentProject }) => {
  const latestProjects = useMemo(() => {
    if (currentProject instanceof Object) {
      return projects.slice(0, 5).filter(p => p.route !== currentProject.route);
    }

    return projects.slice(0, 4);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false
        }
      }
    ]
  };

  return (
    <Section className="latest-works">
      <Container>
        <Box direction="row" basis="full" justify="center">
          <Title level="2" className="title" decorator>
            Our latest projects
          </Title>
        </Box>
      </Container>

      <Container className="slider-container">
        <Box align="center" justify="center" margin={{ bottom: "80px" }}>
          <Box>
            <Slider {...settings}>
              {latestProjects.map(({ title, categories, thumbnail, route }) => (
                <ProjectCard
                  className="card"
                  key={title}
                  title={title}
                  image={thumbnail}
                  categories={categories}
                  route={route}
                />
              ))}
            </Slider>
          </Box>
        </Box>

        <Box direction="row" justify="center">
          <LinkButton route={PROJECTS_PAGE}>See all</LinkButton>
        </Box>
      </Container>
    </Section>
  );
};

export default LatestWorksSection;
