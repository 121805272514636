import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Anchor, Box } from "grommet";
import Slider from "react-slick";
import SEO from "./SEO";
import { Container } from "../elements/Layout";
import { CONTACT_PAGE } from "../../config/routes";
import theme from "../../config/theme";
import { projects } from "../data/posts";
import LatestWorksSection from "./LatestWorksSection";
import { Route } from "../elements/Text";
import DIContainer from "../core/Container";

const StyledPage = styled.div`
  padding: 80px 0;

  .row {
    flex-direction: column;
  }

  .hero-section {
    margin-bottom: 120px;

    .featured-image {
      margin-bottom: 30px;

      img {
        object-fit: contain;
        margin: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .col {
      flex-basis: 50%;
    }

    .categories {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      margin-bottom: 10px;

      span {
        margin-bottom: 10px;
        margin-right: 15px;
        font-weight: bold;
        min-width: 100px;
      }
    }

    .title {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .desc {
      margin-bottom: 30px;
    }

    .meta {
      margin-bottom: 40px;
    }

    .link {
      text-align: center;

      a {
        text-align: center;
        color: ${theme.colors.brand};
        font-weight: 500;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .own-project {
    .title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 120px;
      line-height: 30px;
      margin-left: 15px;
      margin-right: 15px;

      a {
        color: ${theme.colors.brand};

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .slider-container {
    padding: 0;
  }

  .gallery {
    overflow: hidden;

    .slick-slider .slick-next {
      right: -5px;
    }

    .slick-slider .slick-prev {
      left: -5px;
    }

    .slider-container {
      margin-bottom: 200px;

      .gallery-slide {
        padding: 20px;
        outline: 0 !important;
        cursor: pointer;

        img {
          margin: 0 auto;
          width: 100%;
          max-width: 1000px;
        }
      }
    }

    .slick-dots {
      bottom: -73px;
    }
  }

  .back-link {
    display: inline-block;
    margin-bottom: 40px;
    color: ${theme.colors.brand};
    font-family: ${theme.font.primary};
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .featured-image img {
      max-width: 500px;
    }
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .row {
      flex-direction: row;
    }

    .featured-image {
      img {
        width: auto;
        max-height: 500px;
        object-fit: contain;
      }
    }

    .own-project {
      .title {
        font-size: 40px;
      }
    }

    .hero-section {
      .categories {
        flex-direction: row;

        span {
          margin-bottom: 0;
        }
      }

      .link {
        text-align: left;
      }
    }

    .gallery {
      .slider-container {
        .gallery-slide {
          padding: 0;
        }
      }
    }
  }

  @media (min-width: ${theme.breakpoints.xlarge}) {
    .featured-image img {
      max-width: 100%;
    }
  }
`;

const ProjectPage = ({ location }) => {
  const container = DIContainer.getInstance();
  const router = container.getRouter();
  const [currentProject, setCurrentProject] = useState(null);

  useEffect(() => {
    const currentRoute = router.getCurrentRouteName();
    setCurrentProject(projects.find(p => p.route === currentRoute));
  }, []);

  if (!currentProject) {
    return null;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false
        }
      }
    ]
  };

  return (
    <StyledPage>
      <SEO title={`${currentProject.title} project`} location={location} />
      <section className="hero-section">
        <Container>
          <Anchor
            href="#"
            className="back-link"
            plain
            onClick={e => {
              e.preventDefault();
              window.history.back();
            }}
            target="_blank"
            label="Return back"
          />
          <Box direction="row-responsive" className="row" gap="large">
            <div className="col featured-image">
              <img src={currentProject.featuredImage} alt={currentProject.title} />
            </div>
            <div className="col detail">
              <h1 className="title">{currentProject.title}</h1>

              <p className="desc">{currentProject.excerpt}</p>

              <div className="meta">
                <div className="categories">
                  <span>Categories:</span>
                  {currentProject.categories.join(", ")}
                </div>
                <div className="categories">
                  <span>Tags:</span>
                  {currentProject.tags.join(", ")}
                </div>
                <div className="categories">
                  <span>Year:</span>
                  {currentProject.date}
                </div>
              </div>

              <div className="link">
                <a href={currentProject.url} rel="noopener noreferrer" target="_blank">
                  Visit website
                </a>
              </div>
            </div>
          </Box>
        </Container>
      </section>
      <section className="gallery">
        <Container className="slider-container">
          <Slider {...settings}>
            {currentProject.gallery.map(img => (
              <div key={currentProject.title} className="gallery-slide">
                <img src={img} alt={currentProject.title} />
              </div>
            ))}
          </Slider>
        </Container>
      </section>
      <section className="own-project">
        <h3 className="title">
          Want to create your own project? <Route route={CONTACT_PAGE}>Contact&nbsp;us</Route>!
        </h3>
      </section>
      <LatestWorksSection currentProject={currentProject} />
    </StyledPage>
  );
};

export default ProjectPage;
