import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { transparentize } from "polished";
import theme from "../../config/theme";
import { LinkButton } from "../elements/Button";
import { Route } from "../elements/Text";

const StyledProjectCard = styled.div`
  max-width: 329px;

  .inner-wrapper {
    box-shadow: 0 4px 20px 5px rgb(0 0 0 / 50%);
    cursor: pointer;
  }

  .image-wrapper img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .figure {
    position: relative;
    height: 190px;
    background-color: ${theme.colors.dark2};
    font-family: ${theme.font.secondary};
    text-transform: uppercase;
    font-size: 22px;
    padding: 31px 23px 18px;
    overflow: hidden;
    transition: height 0.25s ease-in-out, background-color 0.25s ease-in-out;

    > span {
      z-index: 5;
    }
  }

  .category {
    position: absolute;
    top: 30px;
    font-size: 46px;
    z-index: 2;
    color: ${transparentize(0.8, theme.colors.body)};
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      display: block;
      bottom: -20px;
      height: 3px;
      width: 90px;
      background-color: ${theme.colors.brand};
    }
  }

  .button-wrapper {
    margin-top: 55px;
    text-align: center;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    min-height: 520px;

    .figure {
      background-color: white;
      height: 114px;
    }

    .category {
      font-size: 60px;
    }

    &:hover,
    &:focus {
      .figure {
        height: 190px;
        background-color: ${theme.colors.dark2};
      }
    }
  }
`;

const ProjectCard = ({ className, title, image, categories, route }) => {
  const cat = categories.length > 0 ? categories[0] : "";

  return (
    <StyledProjectCard className={className}>
      <div className="inner-wrapper">
        <Route route={route}>
          <div className="image-wrapper">
            <img src={image} alt={title} />
          </div>
        </Route>
        <div className="figure">
          <span>{title}</span>
          <div className="category">{cat}</div>
          <div className="button-wrapper">
            <LinkButton route={route}>Read more</LinkButton>
          </div>
        </div>
      </div>
    </StyledProjectCard>
  );
};
export default ProjectCard;

ProjectCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  route: PropTypes.string,
  categories: PropTypes.array
};

ProjectCard.defaultProps = {
  categories: []
};
